export default {
  vendor: "Vendor",
  vendor_detail: "Detail Vendor",
  add_vendor: "Add Vendor",
  edit_vendor: "Edit Vendor",
  name_placeholder: "Isi nama perusahaan/perorangan",
  address_placeholder: "Isi alamat lengkap vendor",
  delete_vendor_message: "Anda yakin ingin menghapus vendor ini?",
  reset_vendor_title: "Reset Vendor",
  reset_vendor_message:
    "Kosongkan vendor servis ini. Data vendor tidak akan terhapus dari daftar vendor.",
  info_vendor: "Info Vendor",

  manufacture: "Manufaktur",
  retailer: "Retailer",
  servicer: "Servicer",

  manufacture_detail: "Detail Manufaktur",
  service_center: "Service Center",

  customer_not_set_yet: "Pelanggan belum diset",

  choose_vendor_type: "Pilih Jenis Vendor",

  manufacture_type_desc:
    "Pilih jenis vendor ini jika kamu adalah pembuat produk atau pemilik brand yang terbitkan garansi produk.",
  retailer_type_desc:
    "Pilih jenis vendor ini jika kamu adalah penjual produk yang berperan aktivasi garansi produk dari manufaktur.",
  servicer_type_desc:
    "Pilih jenis vendor ini jika kamu adalah servicer atau pihak yang melakukan servis produk milik pelanggan.",
};
