export default {
  menu: "Menu",
  home: "Home",
  product: "Produk",
  benefit: "Manfaat",
  feature: "Fitur",
  solution: "Solusi",
  faq: "FAQ",
  sclist: "Service Centre List",
  contact: "Kontak",
  our_contact: "Kontak Kami:",

  jumbotron_subheading: "Digital Service Record",
  jumbotron_heading: "Punya mobil keren aja kurang keren",
  jumbotron_description: "Kalo riwayat servisnya masih dicatat manual",
  learn_now: "Pelajari Sekarang",
  order_now: "Pesan Sekarang",
  learn_more: "Pelajari Lebih Lanjut",
  history_subheading: "Riwayat Servis Digital",
  history_heading: `Kelola semua aset\ndalam satu app.`,
  history_description: `Mudah mengelola data aset personal dan\nperusahaan. Simpan dan akses hanya\ndalam hitungan detik!`,
  buy_sticker: "Beli Stiker",

  service_subheading: "Semua Servis",
  service_heading: "Semua jenis servis dalam satu app",
  service_description:
    "Mulai dari servis berkala, ganti oli, filter, aki, spareparts. Semuanya dalam satu app.",
  download_app: "Download App",

  workshop_subheading: "Semua Bengkel",
  workshop_heading: "Satukan data servis semua bengkel",
  workshop_description:
    "Data servis tidak lagi terpisah di setiap bengkel yang pernah kamu kunjungi.",

  vehicle_subheading: "Semua Kendaraan",
  vehicle_heading: "Semua kendaraanmu dalam satu app",
  vehicle_description:
    "Akses data servis semua kendaraanmu kapan saja di mana saja hanya dari satu app.",

  benefit_title_1: "Cloud",
  benefit_description_1: `Dapat diakses kapan \npun dan di mana pun.`,
  benefit_title_2: "Kolaborasi",
  benefit_description_2: `Kelola bersama secara \nreal-time.`,
  benefit_title_3: "Mudah",
  benefit_description_3: `Simpan data mudah \nhanya hitungan detik.`,
  benefit_title_4: "Aman",
  benefit_description_4: `Data tersimpan di \nserver tercanggih.`,
  benefit_title_5: "Tak Terbatas",
  benefit_description_5: `Dapat menampung \ndata tak terhingga.`,
  benefit_title_6: "Inventaris",
  benefit_description_6: `Inventarisasi asetmu \ndengan mudah.`,
  benefit_title_7: "Kuitansi",
  benefit_description_7: `Simpan kuitansi \npembelian dan servis.`,
  benefit_title_8: "Garansi",
  benefit_description_8: `Data garansi tidak \nhilang lagi.`,
  benefit_title_9: "Riwayat",
  benefit_description_9: `Catatan servis \ntersimpan selamanya.`,
  benefit_title_10: "Foto",
  benefit_description_10: `Simpan foto servis \ntanpa tercecer.`,
  benefit_title_11: "Reminder",
  benefit_description_11: `Berfungsi sebagai \npengingat servis.`,
  benefit_title_12: "Bisnis",
  benefit_description_12: `Buka akun bisnis untuk \nfitur lebih lengkap.`,

  sticker_subheading: "Stiker QRID",
  sticker_heading: `Satu aset, \nsatu stiker.`,
  sticker_description:
    "Data setiap aset tersimpan di dalam stiker QRID yang ditempel di tiap aset.",
  buy_qrid_sticker: "Beli Stiker QRID",

  app_subheading: "Mobile App",
  app_heading: `Data selalu ada di \nsaat dibutuhkan.`,
  app_description:
    "Cek dan buat catatan servis asetmu dengan mudah dari aplikasi QRID. Aplikasi gratis selamanya.",

  how_subheading: "Cara Pakai",
  how_heading: "Pemakaian super duper mudah",
  how_description:
    "Scan stiker QRID dengan aplikasi QRID atau hanya dengan kamera hp.",

  feature_title_1: "Riwayat Servis",
  feature_description_1: "QRID dapat menyimpan data servis tak terhingga.",
  feature_title_2: "Akses Online",
  feature_description_2: "Data servis dapat diakses kapan dan di mana pun.",
  feature_title_3: "Data Terpusat",
  feature_description_3: "Semua jenis servis, semua mobilmu, dalam satu app.",
  feature_title_4: "Bagi Akses",
  feature_description_4: "Data bisa dibagi kepada keluarga, teman, dan staf.",
  feature_title_5: "Kunci Akses",
  feature_description_5: "Akses data bisa dikunci untuk pengguna umum.",
  feature_title_6: "Tanpa Sentuh",
  feature_description_6: "Sistem scan QR code cocok di era pasca Covid-19.",

  solution_title_1: "Logistik",
  solution_description_1: `Kelola catatan servis\nkendaraan dan biaya\ndengan mudah.`,
  solution_title_2: "Kesehatan",
  solution_description_2: `Kelola alat kesehatan,\naset lain, dan jadwal\nservisnya.`,
  solution_title_3: "Pendidikan",
  solution_description_3: `Kelola aset tiap kelas,\ncatatan servis, dan\nbiayanya.`,
  solution_title_4: "Olahraga",
  solution_description_4: `Kelola catatan servis\nalat olahraga agar\nlebih awet.`,
  solution_title_5: "Keagamaan",
  solution_description_5: `Kelola inventaris aset\nrumah ibadah agar\ntidak hilang.`,
  solution_title_6: "Manufaktur",
  solution_description_6: `Kelola catatan servis\nmesin pabrik agar\nlebih produktif.`,
  solution_title_7: "Organisasi",
  solution_description_7: `Kelola aset beserta\ncatatan servis secara\nkolaboratif.`,
  solution_title_8: "Pemerintahan",
  solution_description_8: `Kelola aset dan\nriwayat aset agar\nmudah dipantau.`,
  solution_title_9: "Gedung",
  solution_description_9: `Kelola catatan servis\nseluruh aset mall,\nhotel, perkantoran.`,
  solution_title_10: "Bisnis",
  solution_description_10: `Kelola catatan servis\nseluruh aset kantor,\ntoko, semua cabang.`,
  solution_title_11: "Pusat Servis",
  solution_description_11: `Kelola catatan servis\naset pelanggan dan\nfollow up servis.`,
  solution_title_12: "Personal",
  solution_description_12: `Simpan catatan servis\naset di rumah kamu\ndengan mudah.`,

  industry: "Industri",
  industry_title_1: "Sepeda Motor",
  industry_description_1:
    "Motor bebek, matic, sport, pemakaian pribadi, usaha, ojol, kurir, dan lainnya.",
  industry_title_2: "Sedan",
  industry_description_2:
    "Mobil pribadi, taksi pribadi, taksi konvensional, taksi online, dan lainnya.",
  industry_title_3: "Mini Bus",
  industry_description_3:
    "Mobil pribadi, taksi, mobil kantor, sekolah, ambulans, hotel, mall, pemerintahan.",
  industry_title_4: "Bus",
  industry_description_4:
    "Bus kantor, sekolah, travel, pariwisata, angkutan antar kota/pulau, dan lainnya.",
  industry_title_5: "Truk",
  industry_description_5:
    "Truk kargo, logistik, pabrik, minyak, kebun, hutan, tambang dan, lainnya.",
  industry_title_6: "Lain-Lain",
  industry_description_6:
    "Golf car, forklift, excavator, bulldozer, dump truck, ready mix, dan lainnya.",

  faq_question_1: "Apa fungsi aplikasi QRID?",
  faq_answer_1:
    "Aplikasi QRID membantu kamu mengelola semua aset (non living things) yang kamu miliki beserta riwayat servis dan garansi dengan mudah.",
  faq_question_2: "Berapa biaya penggunaan aplikasi QRID?",
  faq_answer_2:
    "Aplikasi QRID tersedia di Google Play dan App Store yang bisa kamu unduh dan gunakan secara gratis selamanya.",
  faq_question_3: "Bagaimana cara kerjanya?",
  faq_answer_3:
    "Kamu harus memiliki stiker QRID untuk bisa mulai menggunakan QRID untuk mengelola aset (non living things) kamu. Kamu bisa beli stiker QRID dari Tokopedia.",
  faq_question_4: "Apakah saya wajib memiliki stiker QRID?",
  faq_answer_4:
    "Ya, sayangnya untuk sementara kamu wajib memiliki stiker QRID untuk membuat aset baru di aplikasi QRID. Namun dalam waktu dekat kamu akan bisa membuat aset baru tanpa stiker QRID. Nantikan update kami selanjutnya ya.",
  faq_question_5: "Apa fungsi stiker QRID?",
  faq_answer_5:
    "Stiker QRID ditempelkan pada setiap aset (non living things) yang kamu miliki dan tiap stiker berfungsi sebagai buku catatan servis setiap asetnya.",
  faq_question_6: "Berapa batas jumlah aset yang bisa ditambah?",
  faq_answer_6:
    "Tidak ada batasan jumlah aset yang bisa ditambahkan dalam aplikasi QRID selama anda memiliki stiker QRID, 1 stiker hanya bisa digunakan untuk 1 aset.",
  faq_question_7:
    "Berapa batas jumlah catatan servis yang bisa ditambah dalam tiap aset?",
  faq_answer_7:
    "Tidak ada batasan jumlah catatan servis yang bisa ditambahkan ke dalam tiap aset.",
  faq_question_8:
    "Berapa batas jumlah user yang bisa ditambahkan untuk kolaborasi?",
  faq_answer_8:
    "Tidak ada batasan jumlah user yang bisa kamu tambahkan ke setiap aset kamu agar bisa dikelola secara kolaboratif.",
  faq_question_9:
    "Apakah data aset saya juga hilang jika stiker QRID saya rusak atau hilang?",
  faq_answer_9:
    "Tidak, data kamu tetap ada di aplikasi QRID. Gunakan fitur Switch QRID untuk pindahkan data aset ke stiker QRID yang baru. Pelajari caranya dari menu tutorial.",

  sclist_heading: "Service Centre List",
  sclist_empty: 'No Result Found For "{{query}}"',
  sclist_error: "Server Error",
  contact_us: "Contact Us",
  qrid: "QRID",
  about_qrid: "Tentang QRID",
  qrid_definition:
    "QRID adalah aplikasi manajemen aset untuk pengguna bisnis atau pribadi. Diluncurkan pada Agustus 2021, QRID memiliki visi untuk menghubungkan konsumen, retail, distributor, pusat servis, hingga manufaktur dengan unifikasi sistem garansi dan servis.",

  qrid_company: "PT QRID Teknologi Indonesia",
  qrid_address:
    "Cyber 2 Tower 17th Floor Jl. HR Rasuna Said, Kuningan Jakarta Selatan",
  qrid_email: "hello@qrid.co",
  qrid_number: "0811 164 1188",
  placeholder: "Search by Jalan, Kota, Nama Bengkel ",
  qrid_download: "Unduh aplikasi QRID",
  footer_follow_us: "Ikuti kami di",
  footer_buy_at_tokopedia: "Beli di Tokopedia",
  footer_download_app: "Download aplikasi di",
  footer_copyrights: "\u00A9 2021-{{year}}. QRID",
  account_type: "Tipe Akun",
  business_account_type: "Tipe Akun Bisnis",
  vendor_type: "Jenis Vendor",
  regular_account: "Akun Regular",
  servicer_account: "Akun Servicer",
  vendor_account: "Akun Vendor",
  regular_account_desc:
    "Kelola inventaris dan catat servis produk atau aset milik akun bisnis ini.",
  vendor_account_desc:
    "Pilih akun ini jika kamu adalah pembuat produk, pemilik brand, penjual produk, atau servicer produk milik pelanggan.",
};
