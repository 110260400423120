export default {
  login: "Login",
  username: "Username",
  password: "Password",
  add: "Tambah",
  data: "Data",
  number: "No.",
  detail: "Detail",
  image: "Gambar",
  description: "Deskripsi",
  asset_description: "Deskripsi Aset",
  price: "Harga",
  code: "Kode",
  save: "Simpan",
  refresh: "Refresh",
  cancel: "Batal",
  team: "Team",
  sure: "Yakin",
  send: "Kirim",
  choose: "Pilih",
  transfer: "Transfer",
  choose_all: "Pilih Semua",
  reset: "Reset",
  no: "Tidak",
  edit: "Edit",
  delete: "Hapus",
  status: "Status",
  submit: "Submit",
  pending: "Pending",
  quantity: "Jumlah",
  label: "Label",
  order: "Urutan",
  type: "Tipe",
  active: "Aktif",
  email: "Email",
  phone: "No. HP",
  started_at: "Dimulai Tanggal",
  ended_at: "Berakhir Tanggal",
  start_at: "Tanggal Awal",
  end_at: "Tanggal Akhir",
  metadata: "Metadata",
  attribute: "Atribut",
  transaction_at: "Transaksi Pada",
  deleted_successfully: "Berhasil Dihapus",
  confirmation: "Konfirmasi",
  confirmation_text: "Apakah anda yakin dengan perubahan ini ?",
  confirmation_delete_text:
    "Apakah anda yakin ingin dengan menghapus data ini ?",
  vendor: "Vendor",
  add_vendor: "Add Vendor",
  edit_vendor: "Edit Vendor",
  buyer: "Pembeli",
  placeholder: "Placeholder",
  search: "Search",
  business: "Bisnis",
  setting: "Setting",
  percent: "Persen",
  total: "Total",
  full_name: "Nama Lengkap",
  gender: "Jenis Kelamin",
  date_of_birth: "Tanggal Lahir",
  insert_your_full_name: "Masukkan nama lengkap kamu",
  choose_your_date_of_birth: "Pilih tanggal lahir kamu",
  insert_your_email: "Masukkan email kamu",
  insert_name_location: "Masukkan Nama {{location}}",
  male: "Pria",
  female: "Wanita",
  add_rent: "Add Penyewa",
  add_team: "Tambah Team",
  edit_team: "Edit Team",

  filter: "Filter",
  add_filter: "Tambah Filter",
  filter_by: "Filter By",
  filter_exact_placeholder: "Filter...",
  apply: "Terapkan",

  change_password: "Ganti Password",
  logout: "Logout",
  old_password: "Password Lama",
  password_confirmation: "Konfirmasi Password",
  password_must_match: "Password Harus Sesuai",

  showing_table_entry_text: "Showing {{from}} to {{to}} of {{total}} data",
  load_more: "Tampilkan Lebih Banyak",

  payment_method: "Metode Pembayaran",

  notifications: "Notifikasi",
  no_notifications: "Tidak ada notifikasi",

  services: "Servis",
  no_services: "Tidak ada servis",

  service_categories: "Kategori Servis",
  no_service_categories: "Tidak ada kategori servis",

  items: "Aset",
  no_items: "Tidak ada aset",

  item_template: "Aset Template",
  no_item_template: "Tidak ada aset template",

  item_categories: "Kategori Aset",
  no_item_categories: "Tidak ada kategori aset",

  banners: "Banner",
  no_banners: "Tidak ada banner",

  metadata_options: "Opsi Metadata",
  no_metadata_options: "Tidak ada opsi metadata",
  add_new: "Tambah Baru",

  shops: "Bisnis",
  no_shops: "Tidak ada Bisnis",
  add_shop: "Tambah Bisnis",
  shop_active: "Bisnis Akan Muncul Di List Service Centre",

  labels: "Label",
  no_labels: "Tidak ada label",

  products: "Produk",
  no_products: "Tidak ada produk",

  users: "Pengguna",
  no_users: "Tidak ada pengguna",

  vendor_users: "Pengguna Vendor",
  no_vendor_users: "Tidak ada pengguna vendor",

  employees: "Karyawan",
  no_employees: "Tidak ada karyawan",

  purchases: "Pembelian",
  no_purchases: "Tidak ada pembelian",

  sales: "Penjualan",
  no_sales: "Tidak ada penjualan",

  permissions: "Permission",

  roles: "Role",
  no_roles: "Tidak ada role",
  role_name: "Nama Role",
  find_role: "Cari Role",
  create_new_role: "Buat Role Baru",
  select_role: "Pilih Role",
  add_role: "Tambah Role",

  master: "Master",
  history: "Riwayat",
  history_item: "Riwayat Aset",
  template: "Template",
  gain: "Perolehan",

  name: "Nama",

  region: "Region",
  find_region: "Cari Region",
  select_region: "Pilih Region",

  created_at: "Tanggal Pembuatan",

  set_order: "Atur Urutan",

  longitude: "Longitude",
  latitude: "Latitude",
  location_url: "Location URL",
  formula: "Formula",

  no_shop_approvals: "Tidak Ada Bisnis Yang Mengajukan Verifikasi",
  shop_approvals: "Verifikasi Bisnis",
  rejected_reason: "Alasan Direject",

  no_user_services: "Tidak Ada Riwayat Servis",

  user_services: "Riwayat Servis",

  tutorial: "Tutorial",
  faq: "FAQ",
  shop_metadata: "Metadata Bisnis",
  primary: "Primary",
  title: "Title",
  no_tutorial: "Tidak Ada Tutorial",
  thumbnail: "Thumbnail",
  tutorial_title: "Judul Tutorial",

  tutorial_categories: "Kategori Tutorial",
  no_tutorial_categories: "Tidak Ada Kategori Tutorial",
  report_stock_file_name: "Laporan Inventaris Stock",
  report_stock_update: "Laporan Update Stock",

  icon: "Icon",
  suffix: "Suffix",
  header: "Header",

  preset: "Preset",
  no_preset: "Tidak Ada Preset",
  add_preset: "Add Preset",

  reminder: "Reminder",

  view_more: "Lihat Selengkapnya",

  step: "Step",
  photo: "Foto",

  report: "Report",
  download: "Download",
  download_success: "Download Berhasil",
  download_photo: "Unduh Foto",
  reschedule: "Undur",
  turn_off: "Matikan",

  copy_presets: "Copy Presets",
  default: "Default",
  is_hide: "Sembunyikan",
  identity: "Identitas",

  service_categories_mass: "Create Mass Kategori Servis",
  service_mass: "Create Mass Servis",

  work_orders: "Work Orders",
  no_work_orders: "Tidak ada Work Orders",

  universal_services: "Universal Services",
  no_universal_services: "Tidak Ada Servis Universal",

  package: "Paket",

  asset: "Aset",
  move_to_do: "Move To Do",

  customer: "Pelanggan",
  customer_name: "Nama Pelanggan",
  add_customer: "Add Pelanggan",
  edit_customer: "Tambah Pelanggan",
  company: "Perusahaan",

  meter: "Catat Meter",
  preset_detail: "Detail Preset",

  single: "Single",
  dashboard: "Dashboard",
  service_history: "Service History",
  service_reminder: "Service Reminder",
  edit_aset: "Edit Aset",
  service_preset: "Service Preset",
  service_package: "Service Package",
  team_member: "Member Team",
  asset_list: "Daftar Aset",
  service_list: "Daftar Servis",
  customer_list: "Daftar Pelanggan",
  customer_aset_list: "Daftar Aset Pelanggan",
  asset_name: "Nama Aset",
  add_button: "Add New",
  showing_item_page:
    "Menampilkan {{from}} - {{to}} dari total {{total}} {{type}}",
  whatsapp: "WhatsApp",
  address: "Alamat",
  unknown: "Unknown",
  aset_list: "Daftar Aset",
  add_admin: "Tambah Admin",

  item_category: "Jenis Aset",
  filter_category: "Filter Kategori",

  consumer: "Pemakai",
  filter_consumer: "Filter Pemakai",
  add_consumer: "Add Pemakai",
  edit_consumer: "Edit Pemakai",
  edit_rent: "Edit Penyewa",
  location: "Lokasi",
  filter_location: "Filter Lokasi",
  add_location: "Add Lokasi",
  location_name: "Nama Lokasi",
  location_type: "Tipe Lokasi",
  level: "Level",
  category: "Kategori",
  date: "Tanggal",
  download_report: "Download Laporan",
  download_service_history: "Download Riwayat Servis",

  today: "Hari Ini",
  yesterday: "Kemarin",
  this_week: "Minggu Ini",
  last_week: "Minggu Lalu",
  this_month: "Bulan Ini",
  last_month: "Bulan Lalu",
  this_year: "Tahun Ini",
  last_year: "Tahun Lalu",
  day: "hari",
  week: "minggu",
  month: "bulan",

  yes: "Iya",

  no_search_result: "Maaf, pencarian tidak ditemukan",
  no_data: "Belum ada data",
  tap_to_retry: "Coba lagi",

  no_connection: "Duh, koneksi internetmu terputus.",
  error_message: "Telah  Terjadi Error",
  back: "Back",
  see_all: "Lihat Semua",

  request: "Request",
  service_record: "Catatan Servis",
  service_detail: "Detail Servis",

  this_month_new_services: "Servis baru bulan ini",
  this_month_repeat_services: "Servis ulang bulan ini",
  this_month_customer: "Pelanggan bulan ini",
  total_asset_count: "Total jumlah aset",
  total_asset: "Jumlah Aset",
  this_month_services: "Servis bulan ini",
  reminder_services: "Servis jatuh tempo",

  top_5_aset_active: "Top 5 Aset Teraktif",
  top_5_team_active: "Top 5 Team Teraktif",
  aset_statistic: "Statistik Aset",

  qrid_desc: "Catatan Digital Asetmu",
  input_phone_number: "Masukkan Nomor Ponsel",
  login_placeholder: "Contoh: 081xxxxxxxxx",
  input_pin: "Masukkan PIN",
  input_otp: "Masukkan OTP",
  reinput_pin: "Masukkan Ulang PIN",
  resend_otp: "Kirim Ulang OTP",
  timer_detik: "{{timer}} detik",

  filter_date: "Filter Tanggal",
  filter_aset_type: "Filter Jenis Aset",
  filter_aset: "Filter Aset",

  pending_service: "Servis Pending",

  label_not_found: "Page not found",
  description_not_found:
    "Uh oh, we cant't seem to find the page you're looking for. Try going back to the asset page",
  button_not_found: "Go To Asset",

  required: "Required",
  optional: "Optional",

  add_member: "Add Member",
  input_telephone_number: "Masukkan Nomor Telepon",
  telephone: "Telepon",

  team_name: "Nama Team",
  delete_access_rights: "Hapus {{name}} sebagai {{role}} dari aset ini?",
  sure_delete_user_from_team: "Yakin mau hapus {{name}} dari team {{team}}?",

  filter_notification: "Filter Jenis Notifikasi",
  others: "Lainnya",

  logout_confirmation: "Apakah anda yakin ingin logout?",
  choose_account: "Pilih Akun",

  service_detail_delete_confirmation:
    "Apakah anda yakin ingin menghapus servis ini?",

  insert_otp_phone:
    "Silahkan masukkan kode OTP yang dikirim ke nomor ponsel kamu.",

  verification_code_incorrect: "Kode verifikasi salah",
  verification_code_sent:
    "Kode verifikasi sudah dikirim, silahkan hubungi kami jika kode belum masuk.",
  verification_code_too_many_request:
    "Permintaan kode verifikasi melebihi batas. Silahkan coba lagi.",
  verification_code_failed_to_send:
    "Permintaan kode verifikasi gagal dikirim. silahkan cek nomor ponsel anda atau coba lagi",
  forget_pin: "Lupa PIN",

  add_asset: "Add Aset",
  add_phone: "Tambah Telepon",
  choose_aset_type: "Pilih Jenis Aset",
  choose_vendor: "Pilih Vendor",
  import_asset: "Import Aset",
  import_asset_desc:
    "Ikuti langkah di bawah untuk import data aset kamu secara massal.",
  import_asset_step_one:
    "1. Download template spreadsheet di bawah untuk isi data aset kamu",
  import_asset_step_two:
    "2. Setelah selesai diisi, upload kembali spreadsheet data aset kamu",
  import_asset_step_three: "3. Pastikan data kamu sudah benar sebelum import",
  total_asset_import: "Total aset {{total}} item",
  download_template: "Download Template",
  upload_data: "Upload Data",
  choose_asset_category: "Pilih Kategori Aset",
  continue: "Lanjut",
  download_file_succesful: "Berhasil download File {{title}}",
  choose_export_file_type: "Pilih Tipe File Export",
  delete_business: "Hapus Bisnis",
  delete_business_desc:
    "Data bisnis yang dihapus tidak bisa dikembalikan lagi.",
  service_name: "Nama Servis",
  type_in_servie_here: "Ketik servis di sini",
  stock_in: "Stok Masuk",
  stock_out: "Stok Keluar",
  last_stock: "Stok Akhir",
  first_stock: "Stok Awal",
  floor: "Lantai",
  building: "Building",
  room: "Ruangan",
  area: "Area",
  city: "Kota",
  position: "Jabatan",
  total_assets: "Jumlah Aset",
  contact: "kontak",
  tomorrow: "Besok",
  after_tomorrow: "Lusa",
  next_week: "Minggu Depan",
  next_two_week: "Minggu Lusa",
  next_month: "Bulan Depan",
  turn_off_reminder: "Matikan Reminder",
  upcoming: "Upcoming",
  follow_up: "Follow Up",
  reminder_detail: "Detail Reminder",
  won: "Won",
  lost: "Lost",
  not_followed_up_yet: "Belum Follow Up",
  already_followed_up: "Sudah Follow Up",
  move_to_due: "Move to Due",
  due: "Due",

  login_to_see_service: "Login untuk lihat riwayat servis",
  remove_preset_confirmation: "Apakah anda yakin untuk menghapus preset ini?",
  profile: "Profil",
  require: "Require",
  data_type: "Jenis Data",
  reminder_name: "Nama Reminder",
  data_name: "Nama Data",
  add_service: "Add Servis",
  add_package: "Add Paket",
  add_data: "Add Data",
};
